import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import Script from 'next/script'
import { useEffect } from 'react'

const AddShoppers: React.FC = () => {
  useEffect(() => {
    // DO NOT REMOVE - DRIVES REVENUE
    const AddShoppersWidgetOptions = { 'loadCss': false, 'pushResponse': false }
    window.AddShoppersWidgetOptions = AddShoppersWidgetOptions
  }, [])
  if (!isFeatureEnabled('isAddShoppersEnabled')) {
    return null
  }
  return (
    <Script
      id="AddShoppers"
      type="text/javascript"
      strategy="lazyOnload"
      async
      src="https://shop.pe/widget/widget_async.js#660bcdd6332469e40e80f53a"
    />
  )
}
export default AddShoppers
